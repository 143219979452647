import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import WhyChooseUsBg from '../../assets/images/why-choose-us/food_5.jpg';
import Icon_Estimates from '../../assets/images/why-choose-us/estimates_2.png';
import Icon_Equipment from '../../assets/images/why-choose-us/equipment_2.png';
import Icon_Work from '../../assets/images/why-choose-us/work_2.png';
import Icon_Irreigation from '../../assets/images/why-choose-us/irreigation_2.png';

const WhyChooseUsOneData = [
    {
        id: uuidv4(),
        heading: 'Ποιότητα',
        description: 'Όλα μας τα γεύματα παρασκευάζονται καθημερινά με τα πιο αγνά υλικά',
        img: Icon_Estimates,
    },
    {
        id: uuidv4(),
        heading: 'Ποικιλία',
        description: 'Ανακαλύψτε την ποικιλία του μενού μας, ζείστε μία γαστρονομική εμπειρία',
        img: Icon_Equipment,
    },
    {
        id: uuidv4(),
        heading: 'Εξυπηρέτηση',
        description: 'Απολαύστε υψηλής ποιότητας εξυπηρέτηση και ατομική προσέγγιση',
        img: Icon_Work,
    },
    {
        id: uuidv4(),
        heading: 'Ταχύτητα',
        description: 'Απολαύστε άμεση παράδοση και την απόλυτη γαστρονομία στην πόρτα σας',
        img: Icon_Irreigation,
    },

];

const WhyChooseUsOneItem = ({ heading, description, img }) => {
    return (
        <div className="col-xl-3 col-lg-3 col-md-6">
            <div className="single-choose-us">
                <div className="single-choose-us-content bb-1 b-color-2">
                    <h4>{heading}</h4>
                    <p>{description}</p>
                </div>
                <div className="single-choose-us-icon">
                    <img src={img} alt="" />
                </div>
            </div>
        </div>
    );
};

const WhyChoseUsOne = () => {
    return (
        <div className="height-592 bg-property pt-80 pb-110" data-overlay="theme-2" data-opacity="7" style={{ backgroundImage: `url(${WhyChooseUsBg})`, backgroundAttachment: 'fixed', backgroundSize: 'cover', backgroundPosition: 'center center' }}>
            <div className="container">
                <div className="row position-relative">
                    <div className="col-xl-12">
                        <div className="section-title text-center mb-60">
                            <h4>Γιατί να επιλέξετε το Ratatouille</h4>
                            <br></br>
                            <h1 className="white-color">Πρωταρχική μας μέριμνα </h1>
                            <br></br>
                            <br></br>
                            <br></br>
                            <br></br>
                            <br></br>
                            <br></br>


                        </div>
                    </div>
                </div>
                <div className="row position-relative">
                    {WhyChooseUsOneData.map((data) => (
                        <WhyChooseUsOneItem
                            key={data.id}
                            heading={data.heading}
                            description={data.description}
                            img={data.img}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default WhyChoseUsOne;
