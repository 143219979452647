
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { FaLink, FaSearchPlus } from 'react-icons/fa';
import Lightbox from "yet-another-react-lightbox";

// import ProjectStyleFourImg0 from '../../assets/images/project/food_compressed/fb_sample.jpg';

import ProjectStyleFourImg1 from '../../assets/images/project/food_compressed/1_pasta_carbo.jpg';
import ProjectStyleFourImg01 from '../../assets/images/project/food_compressed/1_pasta_carbo-transformed.jpeg';

import ProjectStyleFourImg2 from '../../assets/images/project/food_compressed/1_hot_dog.jpg';
import ProjectStyleFourImg02 from '../../assets/images/project/food_compressed/1_hot_dog-transformed.jpeg';

import ProjectStyleFourImg3 from '../../assets/images/project/food_compressed/1_caesars.jpg';
import ProjectStyleFourImg03 from '../../assets/images/project/food_compressed/1_caesars-transformed.jpeg';

import ProjectStyleFourImg4 from '../../assets/images/project/food_compressed/1_club_kotopoulo.jpg';
import ProjectStyleFourImg5 from '../../assets/images/project/food_compressed/1_special_glukoksino.jpg';
import ProjectStyleFourImg6 from '../../assets/images/project/food_compressed/1_gyros.jpg';
import ProjectStyleFourImg7 from '../../assets/images/project/food_compressed/1_kinoa.jpg';
import ProjectStyleFourImg8 from '../../assets/images/project/food_compressed/1_mesogiaki.jpg';
import ProjectStyleFourImg9 from '../../assets/images/project/food_compressed/1_special_parizien.jpg';
import ProjectStyleFourImg10 from '../../assets/images/project/food_compressed/1_pasta_ravioli.jpg';
import ProjectStyleFourImg11 from '../../assets/images/project/food_compressed/1_roka_marouli.jpg';
import ProjectStyleFourImg12 from '../../assets/images/project/food_compressed/1_chicken_sub.jpg';
import ProjectStyleFourImg13 from '../../assets/images/project/food_compressed/1_burger_smashed.jpg';
import ProjectStyleFourImg14 from '../../assets/images/project/food_compressed/1_pulled_pork.jpg';
import ProjectStyleFourImg15 from '../../assets/images/project/food_compressed/1_sandwitch_souvlakia.jpg';
import ProjectStyleFourImg16 from '../../assets/images/project/food_compressed/1_sandwitch_kotosouvlakia.jpg';
import ProjectStyleFourImg17 from '../../assets/images/project/food_compressed/1_club_alantikwn.jpg';
import ProjectStyleFourImg18 from '../../assets/images/project/food_compressed/1_burger_ratatouille.jpg';
import ProjectStyleFourImg19 from '../../assets/images/project/food_compressed/1_burger_cheese.jpg';
import ProjectStyleFourImg20 from '../../assets/images/project/food_compressed/1_burger_chicken.jpg';
import ProjectStyleFourImg21 from '../../assets/images/project/food_compressed/1_pasta_rata.jpg';
import ProjectStyleFourImg22 from '../../assets/images/project/food_compressed/1_pasta_mesogeiaki.jpg';
import ProjectStyleFourImg23 from '../../assets/images/project/food_compressed/1_pasta_bolognese.jpg';
import ProjectStyleFourImg24 from '../../assets/images/project/food_compressed/1_pasta_napolitana.jpg';
import ProjectStyleFourImg25 from '../../assets/images/project/food_compressed/1_pasta_voutyrou.jpg';
import ProjectStyleFourImg26 from '../../assets/images/project/food_compressed/1_special_magoula.jpg';
import ProjectStyleFourImg27 from '../../assets/images/project/food_compressed/1_special_sous_vide.jpg';
import ProjectStyleFourImg28 from '../../assets/images/project/food_compressed/1_grill_kotosouvlakia.jpg';
import ProjectStyleFourImg29 from '../../assets/images/project/food_compressed/1_grill_mpifteki.jpg';
import ProjectStyleFourImg30 from '../../assets/images/project/food_compressed/1_grill_mpifteki_gemisto.jpg';
import ProjectStyleFourImg31 from '../../assets/images/project/food_compressed/1_grill_mprizolakia.jpg';
import ProjectStyleFourImg36 from '../../assets/images/project/food_compressed/1_grill_souvlakia_xoirina.jpg';
import ProjectStyleFourImg32 from '../../assets/images/project/food_compressed/1_kotompoukies.jpg';
import ProjectStyleFourImg33 from '../../assets/images/project/food_compressed/1_grill_fileto.jpg';
import ProjectStyleFourImg34 from '../../assets/images/project/food_compressed/1_salata_rata.jpg';
import ProjectStyleFourImg35 from '../../assets/images/project/food_compressed/1_laxano_karoto.jpg';
import ProjectStyleFourImg04 from '../../assets/images/project/food_compressed/1_club_kotopoulo-transformed.jpeg';
import ProjectStyleFourImg05 from '../../assets/images/project/food_compressed/1_special_glukoksino-transformed.jpeg';
import ProjectStyleFourImg06 from '../../assets/images/project/food_compressed/1_gyros-transformed.jpeg';
import ProjectStyleFourImg07 from '../../assets/images/project/food_compressed/1_kinoa-transformed.jpeg';
import ProjectStyleFourImg08 from '../../assets/images/project/food_compressed/1_mesogiaki-transformed.jpeg';
import ProjectStyleFourImg09 from '../../assets/images/project/food_compressed/1_special_parizien-transformed.jpeg';
import ProjectStyleFourImg010 from '../../assets/images/project/food_compressed/1_pasta_ravioli-transformed.jpeg';
import ProjectStyleFourImg011 from '../../assets/images/project/food_compressed/1_roka_marouli-transformed.jpeg';
import ProjectStyleFourImg012 from '../../assets/images/project/food_compressed/1_chicken_sub-transformed.jpeg';
import ProjectStyleFourImg013 from '../../assets/images/project/food_compressed/1_burger_smashed-transformed.jpeg';
import ProjectStyleFourImg014 from '../../assets/images/project/food_compressed/1_pulled_pork-transformed.jpeg';
import ProjectStyleFourImg015 from '../../assets/images/project/food_compressed/1_sandwitch_souvlakia-transformed.jpeg';
import ProjectStyleFourImg016 from '../../assets/images/project/food_compressed/1_sandwitch_kotosouvlakia-transformed.jpeg';
import ProjectStyleFourImg017 from '../../assets/images/project/food_compressed/1_club_alantikwn-transformed.jpeg';
import ProjectStyleFourImg018 from '../../assets/images/project/food_compressed/1_burger_ratatouille-transformed.jpeg';
import ProjectStyleFourImg019 from '../../assets/images/project/food_compressed/1_burger_cheese-transformed.jpeg';
import ProjectStyleFourImg020 from '../../assets/images/project/food_compressed/1_burger_chicken-transformed.jpeg';
import ProjectStyleFourImg021 from '../../assets/images/project/food_compressed/1_pasta_rata-transformed.jpeg';
import ProjectStyleFourImg022 from '../../assets/images/project/food_compressed/1_pasta_mesogeiaki-transformed.jpeg';
import ProjectStyleFourImg023 from '../../assets/images/project/food_compressed/1_pasta_bolognese-transformed.jpeg';
import ProjectStyleFourImg024 from '../../assets/images/project/food_compressed/1_pasta_napolitana-transformed.jpeg';
import ProjectStyleFourImg025 from '../../assets/images/project/food_compressed/1_pasta_voutyrou-transformed.jpeg';
import ProjectStyleFourImg026 from '../../assets/images/project/food_compressed/1_special_magoula-transformed.jpeg';
import ProjectStyleFourImg027 from '../../assets/images/project/food_compressed/1_special_sous_vide-transformed.jpeg';
import ProjectStyleFourImg028 from '../../assets/images/project/food_compressed/1_grill_kotosouvlakia-transformed.jpeg';
import ProjectStyleFourImg029 from '../../assets/images/project/food_compressed/1_grill_mpifteki-transformed.jpeg';
import ProjectStyleFourImg030 from '../../assets/images/project/food_compressed/1_grill_mpifteki_gemisto-transformed.jpeg';
import ProjectStyleFourImg031 from '../../assets/images/project/food_compressed/1_grill_mprizolakia-transformed.jpeg';
import ProjectStyleFourImg036 from '../../assets/images/project/food_compressed/1_grill_souvlakia_xoirina-transformed.jpeg';
import ProjectStyleFourImg032 from '../../assets/images/project/food_compressed/1_kotompoukies-transformed.jpeg';
import ProjectStyleFourImg033 from '../../assets/images/project/food_compressed/1_grill_fileto-transformed.jpeg';
import ProjectStyleFourImg034 from '../../assets/images/project/food_compressed/1_salata_rata-transformed.jpeg';
import ProjectStyleFourImg035 from '../../assets/images/project/food_compressed/1_laxano_karoto-transformed.jpeg';
import ProjectStyleFourImg045 from '../../assets/images/project/food_compressed/1_torteloni.jpg';




const ProjectStyleFourData = [
    {
        id: uuidv4(),
        img: ProjectStyleFourImg012,
        heading: 'Ψωμάκι brioche, τραγανό παναρισμένο φιλέτο κοτόπουλο, μπέικον, τσένταρ σώς, ντομάτα, iceberg, σώς cocktail',
        btnLink: 'Chicken Sub $€5,30',
        category: 'sandwich',
        imgPopup: ProjectStyleFourImg12,
    },
    {
        id: uuidv4(),
        img: ProjectStyleFourImg014,
        heading: 'Ψημένη ciabatta με σιγομαγειρεμένο χοιρινό, coleslaw, τσένταρ, μπάρμπεκιου, μαγιονέζα',
        btnLink: 'Pulled Pork $€5,80',
        category: 'sandwich',
        imgPopup: ProjectStyleFourImg14,
    },

    {
        id: uuidv4(),
        img: ProjectStyleFourImg013,
        heading: '2 χειροποίητα μοσχαρίσια μπιφτέκια 200γρ, καραμελωμένα κρεμμύδια, τραγανό μπέικον, τσένταρ, μαγιονέζα, κέτσαπ, σε ψωμάκι brioche. Συνοδεύεται με φλουδάτες τηγανητές πατάτες',
        btnLink: 'Smashed Μπέργκερ $€7,90',
        category: 'burger',
        imgPopup: ProjectStyleFourImg13,
    },
    
    {
        id: uuidv4(),
        img: ProjectStyleFourImg06,
        heading: 'Σε πίτα ανατολής, με σώς γιαουρτιού, τηγανητό κρεμμύδι , ντομάτα και chips πατάτας',
        btnLink: 'Χειροποίητος χοιρινός Γύρος $€4,50',
        category: 'sandwich',
        imgPopup: ProjectStyleFourImg6,
    },
    
    {
        id: uuidv4(),
        img: ProjectStyleFourImg02,
        heading: 'Ψωμάκι brioche, coleslaw, μουστάρδα καπνιστή, κέτσαπ, chips πατάτας',
        btnLink: 'Hot-Dog $€4,20',
        category: 'sandwich',
        imgPopup: ProjectStyleFourImg2,
    },
    {
        id: uuidv4(),
        img: ProjectStyleFourImg03,
        heading: 'Αυθεντική εκτέλεση Caesars με τραγανό κοτόπουλο, μπέικον, iceberg, μαρούλι, κρουτόν, παρμεζάνα',
        btnLink: 'Σαλάτα Ceasars $€6,90',
        category: 'salates',
        imgPopup: ProjectStyleFourImg3,
    },
    
    {
        id: uuidv4(),
        img: ProjectStyleFourImg04,
        heading: 'Παναρισμένο κοτόπουλο, μπέικον, κασέρι, ντομάτα, iceberg, σώς μουστάρδας, μαγιονέζα. Συνοδεύεται με φλουδάτες τηγανητές πατάτες',
        btnLink: 'Κλαμπ Κοτοπουλο $€7,80',
        category: 'club',
        imgPopup: ProjectStyleFourImg4,
    },
    
    {
        id: uuidv4(),
        img: ProjectStyleFourImg05,
        heading: 'Φιλετάκια χοιρινά, γλυκόξινη σάλτσα, ανανάς, μανιτάρια, πολύχρωμες πιπεριές, συνοδεύεται με αρωματικό ρύζι μπασμάτι',
        btnLink: 'Γλυκόξινο Χοιρινό $€7,80',
        category: 'special',
        imgPopup: ProjectStyleFourImg5,
    },
    
    {
        id: uuidv4(),
        img: ProjectStyleFourImg01,
        heading: 'Με αυγό, καπνιστό μπέικον, παρμεζάνα και μανιτάρια',
        btnLink: 'Καρμπονάρα $€7,30',
        category: 'zimarika',
        imgPopup: ProjectStyleFourImg1,
    },
    // {
    //     id: uuidv4(),
    //     img: ProjectStyleFourImg026,
    //     heading: 'Σιγομαγειρεμένα μάγουλα με κόκκινο κρασί, μαύρη μπύρα και ζωμό κρέατος. Συνοδεύεται με πουρέ πατάτας',
    //     btnLink: 'Μοσχαρίσια Μάγουλα $€9,50',
    //     category: 'special',
    //     imgPopup: ProjectStyleFourImg26,
    // },
    {
        id: uuidv4(),
        img: ProjectStyleFourImg027,
        heading: 'Φιλέτο μαγειρεμένο σε sous vide, με ελαφριά σάλτσα μουστάρδας. Συνοδεύεται με πουρέ καρότο αρωματισμένο με πορτοκάλι',
        btnLink: 'Sous Vide Κοτόπουλο $€7,50',
        category: 'special',
        imgPopup: ProjectStyleFourImg27,
    },


    {
        id: uuidv4(),
        img: ProjectStyleFourImg028,
        heading: 'Συνοδεύεται με πιτούλες ανατολής και τηγανητές πατάτες',
        btnLink: 'Κοτοσουβλάκια Χειροποίητα (4 τεμ) $€6,90',
        category: 'schara',
        imgPopup: ProjectStyleFourImg28,
    },
    // {
    //     id: uuidv4(),
    //     img: ProjectStyleFourImg07,
    //     heading: 'Με φρέσκο δυόσμο, λαχανικά και dressing εσπεριδοειδών',
    //     btnLink: 'Σαλάτα Κινόα $€5,20',
    //     category: 'salates',
    //     imgPopup: ProjectStyleFourImg7,
    // },
    
    // {
    //     id: uuidv4(),
    //     img: ProjectStyleFourImg08,
    //     heading: 'Ντομάτα, αγγούρι, μους ελιάς, φέτα, κριθαροκούλουρα χαρουπιού, πιπεριά, φρ. κρεμμύδι',
    //     btnLink: 'Σαλάτα Μεσογειακή $€6,50',
    //     category: 'salates',
    //     imgPopup: ProjectStyleFourImg8,
    // },
    
    {
        id: uuidv4(),
        img: ProjectStyleFourImg09,
        heading: 'Φιλέτο κοτόπουλο, Μανιτάρια, Πράσινες πιπεριές, ελαφριά κρέμα γάλακτος, συνοδεύεται με ρύζι μπασμάτι',
        btnLink: 'Παριζιέν Κοτόπουλο $€7,80',
        category: 'special',
        imgPopup: ProjectStyleFourImg9,
    },
    
    {
        id: uuidv4(),
        img: ProjectStyleFourImg010,
        heading: 'Γεμιστό με τυρί και σπανάκι, λάδι τρούφας, ελαφριά κρέμα γάλακτος προσούτο και παρμεζάνα',
        btnLink: 'Ravioli (Fresh pasta) $€8,00',
        category: 'zimarika',
        imgPopup: ProjectStyleFourImg10,
    },
    
    {
        id: uuidv4(),
        img: ProjectStyleFourImg011,
        heading: 'Με παρμεζάνα, ντοματίνια, κρουτόν, λιαστή ντομάτα, dressing βαλσάμικο',
        btnLink: 'Σαλάτα Ρόκα Μαρούλι $€6,30',
        category: 'salates',
        imgPopup: ProjectStyleFourImg11,
    },


    {
        id: uuidv4(),
        img: ProjectStyleFourImg015,
        heading: 'Ψωμάκι panini, καραμελωμένα κρεμμύδια, σώς γιαουρτιού, ντομάτα, πατάτες τηγανιτές',
        btnLink: 'Σουβλάκι Χοιρινό $€5,20',
        category: 'sandwich',
        imgPopup: ProjectStyleFourImg15,
    },

    {
        id: uuidv4(),
        img: ProjectStyleFourImg016,
        heading: 'Ψωμάκι panini, iceberg, πιπεριές, ντοματίνια, καπνιστή μουσταρδομαγιονέζα, πατάτες τηγανητές',
        btnLink: 'Κοτοσουβλάκια $€5,20',
        category: 'sandwich',
        imgPopup: ProjectStyleFourImg16,
    },

    {
        id: uuidv4(),
        img: ProjectStyleFourImg017,
        heading: 'Μπέικον, χοιρινή ωμοπλάτη, gouda, ντομάτα, μαρούλι, μαγιονέζα, κέτσαπ. Συνοδεύεται με φλουδάτες τηγανητές πατάτες',
        btnLink: 'Κλαμπ Αλλαντικών $€6,50',
        category: 'club',
        imgPopup: ProjectStyleFourImg17,
    },

    {
        id: uuidv4(),
        img: ProjectStyleFourImg018,
        heading: 'Χειροποίητο μοσχαρίσιο μπιφτέκι 200γρ, καπνιστή μουσταρδομαγιονέζα, τσένταρ, μπέικον, iceberg, ντομάτα, σε ψωμάκι brioche. Συνοδεύεται με φλουδάτες τηγανητές πατάτες',
        btnLink: 'Μπέργκερ Ratatouille $€8,00',
        category: 'burger',
        imgPopup: ProjectStyleFourImg18,
    },

    {
        id: uuidv4(),
        img: ProjectStyleFourImg019,
        heading: 'Χειροποίητο μοσχαρίσιο μπιφτέκι 200γρ, καπνιστή μουσταρδομαγιονέζα, κέτσαπ, τσένταρ .Συνοδεύεται με φλουδάτες τηγανητές πατάτες',
        btnLink: 'Cheese Μπέργκερ $€6,80',
        category: 'burger',
        imgPopup: ProjectStyleFourImg19,
    },

    {
        id: uuidv4(),
        img: ProjectStyleFourImg020,
        heading: 'Χειροποίητο μπιφτέκι κοτόπουλο 200γρ, μπέικον, καπνιστή μουστάρδα, iceberg, κασέρι, ντομάτα. Συνοδεύεται με φλουδάτες τηγανητές πατάτες',
        btnLink: 'Μπέργκερ Κοτόπουλο $€7,50',
        category: 'burger',
        imgPopup: ProjectStyleFourImg20,
    },


    {
        id: uuidv4(),
        img: ProjectStyleFourImg021,
        heading: 'Πένες, κοτόπουλο, μπρόκολο, καπνιστό μπέικον, παρμεζάνα',
        btnLink: 'Ζυμαρικά Ratatouille $€7,80',
        category: 'zimarika',
        imgPopup: ProjectStyleFourImg21,
    },
    {
        id: uuidv4(),
        img: ProjectStyleFourImg022,
        heading: 'Linguine, φέτα, ελιά, λιαστή ντομάτα, πιπεριές, μανιτάρια, φρέσκο βασιλικό',
        btnLink: 'Ζυμαρικά Μεσογειακά $€7,30',
        category: 'zimarika',
        imgPopup: ProjectStyleFourImg22,
    },
    {
        id: uuidv4(),
        img: ProjectStyleFourImg023,
        heading: 'Με ζυμαρικό της επιλογής σας',
        btnLink: 'Bolognese $€7,30',
        category: 'zimarika',
        imgPopup: ProjectStyleFourImg23,
    },
    {
        id: uuidv4(),
        img: ProjectStyleFourImg024,
        heading: 'Με ζυμαρικό της επιλογής σας',
        btnLink: 'Napolitana $€6,00',
        category: 'zimarika',
        imgPopup: ProjectStyleFourImg24,
    },
    {
        id: uuidv4(),
        img: ProjectStyleFourImg025,
        heading: 'Με ζυμαρικό της επιλογής σας',
        btnLink: 'Ζυμαρικά Βουτύρου $€4,50',
        category: 'zimarika',
        imgPopup: ProjectStyleFourImg25,
    },

    {
        id: uuidv4(),
        img: ProjectStyleFourImg036,
        heading: 'Συνοδεύεται με πιτούλες ανατολής και τηγανητές πατάτες',
        btnLink: 'Χοιρινά Σουβλάκια Χειροποίητα (4τεμ) $€6,90',
        category: 'schara',
        imgPopup: ProjectStyleFourImg36,
    },
    {
        id: uuidv4(),
        img: ProjectStyleFourImg029,
        heading: 'Συνοδεύεται με πιτούλες ανατολής και τηγανητές πατάτες',
        btnLink: 'Μοσχαρίσια Μπιφτέκια (2τεμ) $€6,90',
        category: 'schara',
        imgPopup: ProjectStyleFourImg29,
    },
    {
        id: uuidv4(),
        img: ProjectStyleFourImg030,
        heading: 'Συνοδεύεται με πιτούλες ανατολής και τηγανητές πατάτες',
        btnLink: 'Μπιφτέκι Γεμιστό (1 τεμ) $€7,00',
        category: 'schara',
        imgPopup: ProjectStyleFourImg30,
    },
    // {
    //     id: uuidv4(),
    //     img: ProjectStyleFourImg031,
    //     heading: 'Συνοδεύεται με πιτούλες ανατολής και τηγανητές πατάτες',
    //     btnLink: 'Μπριζολάκια Χοιρινά (2 τεμ) $€7,00',
    //     category: 'schara',
    //     imgPopup: ProjectStyleFourImg31,
    // },
    {
        id: uuidv4(),
        img: ProjectStyleFourImg032,
        heading: 'Χειροποίητες τραγάνες κοτομπουκιές, συνοδεύονται με πατάτες τηγανητές ,λιωμένο cheddar και μπέικον. Συνοδεύεται με πιτούλες ανατολής και τηγανητές πατάτες',
        btnLink: 'Κοτομπουκιές Ratatouille $€6,90',
        category: 'special',
        imgPopup: ProjectStyleFourImg32,
    },
    {
        id: uuidv4(),
        img: ProjectStyleFourImg033,
        heading: 'Συνοδεύεται με πιτούλες ανατολής και τηγανητές πατάτες',
        btnLink: 'Φιλέτο Κοτόπουλο $€6,90',
        category: 'schara',
        imgPopup: ProjectStyleFourImg33,
    },

    {
        id: uuidv4(),
        img: ProjectStyleFourImg034,
        heading: 'Πράσινη σαλάτα με ροκφόρ, φιλέτο πορτοκάλι, ντοματίνια και dressing οξύμελο',
        btnLink: 'Σαλάτα Ratatouille $€6,30',
        category: 'salates',
        imgPopup: ProjectStyleFourImg34,
    },
    // {
    //     id: uuidv4(),
    //     img: ProjectStyleFourImg035,
    //     heading: 'Με μπουκιές τυριού, πιπεριά φλωρίνης, ραπανάκι και μαϊντανό',
    //     btnLink: 'Σαλάτα Λάχανο Καρότο $€5,50',
    //     category: 'salates',
    //     imgPopup: ProjectStyleFourImg35,
    // },
    {
        id: uuidv4(),
        img: ProjectStyleFourImg045,
        heading: 'Γεμιστό με 3 τυριά & μανιτάρι porcini, παρμεζάνα, λευκά μανιτάρια, λάδι τρούφας',
        btnLink: 'Tortelloni Fresh Pasta $€8,00',
        category: 'zimarika',
        imgPopup: ProjectStyleFourImg045,
    },
];

const ProjectStyleFourItem = ({ img, imgPopup, heading, btnLink }) => {

    const [open, setOpen] = React.useState(false);
    return (
        <>  
            
            <div className="col-xl-3 col-lg-3 col-md-4 planting text-center ">
                <div className="single-project-isotope-style-2 bg-property mb-2 rounded" style={{ backgroundImage: `url(${img})` }}>
                    <div className="portfolio-content-2">
                        <button type="button" onClick={() => setOpen(true)} className="popup-image">
                            <FaSearchPlus />
                        </button>
                        <Lightbox
                            open={open}
                            close={() => setOpen(false)}
                            slides={[
                                { src: imgPopup },
                            ]}
                        />
                    </div>
                    <div className="portfolio-heading-content-2">
                        <h3 >{heading}</h3>
                    </div>
                </div>
                <h5 className='pb-40'>
                    {btnLink.split('$').map((word, index) => (
                        <span key={index} className='pb-1' style={{ display: 'block', color: 'black', fontSize: '22px' }}>{word}</span>
                    ))}
                </h5>

            </div>
        </>
    )
}

const CategoryBtn = ({ name, handleSetCategory, label, className }) => {
    return (
        <>
            <button
                className={`category-btn ${className}`}
                type="button"
                onClick={() => handleSetCategory(name)}
            >
                {label}
            </button>
            <style>
                {`
                    .category-btn {
                        cursor: pointer;
                        background-color: #CFA349;
                        color: #ffffff;
                        font-size: 16px;
                        padding: 14px 15px;
                        margin: 2px;
                        border: none;
                        border-radius: 15px;
                        transition: background-color 0.3s ease, color 0.3s ease;
                    }

                    .category-btn:hover {
                        background-color: #A2843D;
                        /* Add any other styles for hover effect */
                    }

                    .category-btn.active {
                        font-weight: bold;
                        // text-decoration: underline;
                        background-color: #A2843D;
                        /* Add any other styles for the active state */
                    }
                `}
            </style>
        </>
    );
};

const ProjectStyleFour = () => {
    const [category, setCategory] = useState('all');
    const [projectStyleFourItem, setProjectStyleFourItem] = useState([]);
    const [itemsToShow, setItemsToShow] = useState(8);
    const [itemsToLoad, setItemsToLoad] = useState(8);

    useEffect(() => {
        category === 'all'
            ? setProjectStyleFourItem(ProjectStyleFourData)
            : setProjectStyleFourItem(ProjectStyleFourData.filter((data) => data.category === category));
    }, [category]);

    const loadMoreItems = () => {
        setItemsToShow(itemsToShow + itemsToLoad);
    };

    const handleItemsToLoadChange = (newItemsToLoad) => {
        setItemsToLoad(newItemsToLoad);
    };

    return (
        <>
            <style>
            {`
                .btn-custom {
                    background-color: #CFA349; /* Replace with the actual color value */
                    color: #ffffff !important; /* Replace with the actual text color value */
                    font-size: 16px; /* Replace with your desired font size */
                    /* Add any other styling properties as needed */
                }

                .btn-custom:hover {
                    background-color: #A2843D !important; /* Replace with the hover color value */
                    /* You can adjust other properties for the hover state as needed */
                }
                .portfolio-menu button {
                    font-size: 18px; /* Adjust font size as needed */
                }
                @media only screen and (max-width: 1500px) {
                    .col-xl-3,
                    .col-lg-3 {
                        flex: 0 0 50%;
                        max-width: 50%;
                    }
            `}
            </style>
            <div className="project-area pt-50 pb-130">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section-title text-center mb-40">
                                <h1>Το Μενού μας</h1>
                                <h4>Πατήστε τη φωτογραφία για λεπτομέρειες υλικών</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-lg-12 text-center">
                            <div className="portfolio-menu mb-90 rounded">
                                <CategoryBtn
                                    className={category === 'all' ? 'active' : null}
                                    name="all"
                                    label="Όλα"
                                    handleSetCategory={setCategory}
                                />
                                <CategoryBtn
                                    className={category === 'sandwich' ? 'active' : null}
                                    name="sandwich"
                                    label="Σάντουιτς"
                                    handleSetCategory={setCategory}
                                />
                                <CategoryBtn
                                    className={category === 'club' ? 'active' : null}
                                    name="club"
                                    label="Κλάμπ Σάντουιτς"
                                    handleSetCategory={setCategory}
                                />
                                <CategoryBtn
                                    className={category === 'burger' ? 'active' : null}
                                    name="burger"
                                    label="Μπέργκερ"
                                    handleSetCategory={setCategory}
                                />
                                <CategoryBtn
                                    className={category === 'zimarika' ? 'active' : null}
                                    name="zimarika"
                                    label="Ζυμαρικά"
                                    handleSetCategory={setCategory}
                                />
                                <CategoryBtn
                                    className={category === 'special' ? 'active' : null}
                                    name="special"
                                    label="Σπέσιαλ Πιάτα"
                                    handleSetCategory={setCategory}
                                />
                                <CategoryBtn
                                    className={category === 'schara' ? 'active' : null}
                                    name="schara"
                                    label="Μερίδες"
                                    handleSetCategory={setCategory}
                                />
                                <CategoryBtn
                                    className={category === 'salates' ? 'active' : null}
                                    name="salates"
                                    label="Σαλάτες"
                                    handleSetCategory={setCategory}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row portfolio-active">
                        {projectStyleFourItem
                            .slice(0, itemsToShow)
                            .map((item) => (
                                <ProjectStyleFourItem
                                    key={item.id}
                                    img={item.img}
                                    imgPopup={item.imgPopup}
                                    heading={item.heading}
                                    category={item.category}
                                    btnLink={item.btnLink}
                                />
                            ))}
                   </div>
                    {itemsToShow < projectStyleFourItem.length && (
                        <div className="text-center">
                            <button type="button" onClick={loadMoreItems} className="btn btn-custom">
                                Περισσότερα
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default ProjectStyleFour;
