import React from 'react';
import {Link} from 'react-router-dom';
import { FaLocationArrow } from 'react-icons/fa'; // Import the FaLocationArrow icon

const Breadcrumb = ({ breadcrumbBg, heading, description, currentPage }) => {
    return (
        <>
            <div className="breadcrumb-area pt-160 pb-160 bg-property" data-overlay="black" data-opacity="7" style={{ backgroundImage: `url(${breadcrumbBg})` }}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 justify-content-center">
                            <div className="breadcrumb-content-wrapper text-center">
                                <h2>{heading}</h2>
                                <p style={{ fontSize: '22px' }}>{description}</p>
                                <p style={{ fontSize: '22px' }}>Εδέσσης 17 στις Σέρρες για παραλλαβή απο το κατάστημα</p>
                                <div className="l-btn">
                                    <a href="https://maps.app.goo.gl/tXY9qd8i2Xv93YiJA" target="_blank"><FaLocationArrow /> ΒΡΕΙΤΕ ΜΑΣ ΕΔΩ</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="breadcrumb-navigation">
                                <nav aria-label="breadcrumb">
                                    <ul>
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">{currentPage}</li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Breadcrumb;