import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';
import { FaAngleDoubleRight, FaAngleRight, FaAngleLeft } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';
import './ProjectStyleOneStyle.css';

import ProjectStyleOneImg1 from '../../assets/images/project/food_compressed/1_pulled_pork-transformed.jpeg';
import ProjectStyleOneImg2 from '../../assets/images/project/food_compressed/1_burger_smashed-transformed.jpeg';
import ProjectStyleOneImg3 from '../../assets/images/project/food_compressed/1_pasta_rata-transformed.jpeg';
import ProjectStyleOneImg4 from '../../assets/images/project/food_compressed/1_special_glukoksino-transformed.jpeg';
import ProjectStyleOneImg5 from '../../assets/images/project/food_compressed/1_grill_souvlakia_xoirina-transformed.jpeg';
import ProjectStyleOneImg6 from '../../assets/images/project/food_compressed/1_salata_rata-transformed.jpeg';
import ProjectStyleOneImg7 from '../../assets/images/project/food_compressed/1_club_kotopoulo-transformed.jpeg';






const ProjectStyleOneData = [
    
    {
        id: uuidv4(),
        img: ProjectStyleOneImg1,
        heading: 'Σάντουιτς',
        description: 'Ανακαλύψτε τα ιδιαίτερα μας σάντουιτς. Κάθε δάγκωμα αποκαλύπτει μια έκρηξη από φρέσκα, ποιοτικά υλικά και πρωτότυπες γεύσεις που ξυπνούν τις αισθήσεις',
        btnLink: '/menu',
    },

    {
        id: uuidv4(),
        img: ProjectStyleOneImg2,
        heading: 'Μπέργκερ',
        description: 'Εξερευνήστε τα μοναδικά μας μπέργκερ. Μια ξεχωριστή παλέτα από πλούσιες γεύσεις και αγνά υλικά, συνδυασμένα τέλεια για κάθε γαστρονομική επιθυμία',
        btnLink: '/menu',
    },

    {
        id: uuidv4(),
        img: ProjectStyleOneImg3,
        heading: 'Ζυμαρικά',
        description: 'Γευθείτε τα φρέσκα ζυμαρικά μας. Κάθε μπουκιά συνδυάζει αυθεντικές γεύσεις και φρέσκα υλικά, δημιουργώντας γαστρονομικές απολαύσεις που ξεχωρίζουν',
        btnLink: '/menu',
    },

    {
        id: uuidv4(),
        img: ProjectStyleOneImg7,
        heading: 'Κλάμπ Σάντουιτς',
        description: 'Δοκιμάστε τα φρεσκότατα κλαμπ μας. Με επιλεγμένα αλλαντικά, τυριά και λαχανικά, κάθε κλαμπ αναδεικνύει τη γευστική ποικιλία σε κάθε μπουκιά',
        btnLink: '/menu',
    },

    {
        id: uuidv4(),
        img: ProjectStyleOneImg4,
        heading: 'Σπέσιαλ Πιάτα',
        description: 'Ανακαλύψτε τις ιδιαίτερες γαστρονομικές μας δημιουργίες. Σε αυτήν την κατηγορία, θα ανακαλύψετε συναρπαστικές επιλογές που θα βρείτε αποκλειστικά σε εμας',
        btnLink: '/menu',
    },

    {
        id: uuidv4(),
        img: ProjectStyleOneImg5,
        heading: 'Μερίδες',
        description: 'Απολαύστε τις νόστιμες μερίδες μας. Προσφέρουμε μια ποικιλία από λαχταριστές επιλογές που θα ικανοποιήσουν την απαιτητική σας γευστική ανησυχία',
        btnLink: '/menu',
    },

    {
        id: uuidv4(),
        img: ProjectStyleOneImg6,
        heading: 'Σαλάτες',
        description: 'Δοκιμάστε τις φρεσκοκομμένες σαλάτες μας. Πλούσιες σε γεύση και θρεπτικές ουσίες, αποτελούν την ιδανική επιλογή για μια υγιεινή και νόστιμη γευστική εμπειρία',
        btnLink: '/menu',
    },

    

    
];


const NextArrow = (props) => {
    const { onClick } = props;
    return (
      <div
        className="slick-arrow slick-next"
        style={{
          fontSize: '45px',
          color: '#CFA349',
          backgroundColor: '#fff',
          borderRadius: '50%',
          width: '46px',
          height: '46px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
          top: '50%',
          right: '-0px', // Adjust positioning
          cursor: 'pointer',
          zIndex: '1',
        //   border: 'none', // Remove the border
        }}
        onClick={onClick}
      >
        <FaAngleRight />
      </div>
    );
  };
  
  const PrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div
        className="slick-arrow slick-prev"
        style={{
          fontSize: '45px',
          color: '#CFA349',
          backgroundColor: '#fff',
          borderRadius: '50%',
          width: '46px',
          height: '46px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
          top: '50%',
          left: '-0px', // Adjust positioning
          cursor: 'pointer',
          zIndex: '1',
        }}
        onClick={onClick}
      >
        <FaAngleLeft />
      </div>
    );
  };
  
  const ProjectStyleOneItem = ({ img, heading, description }) => {
    return (
      <div className="single-project" style={{ margin: '15px 10px' }}>
        <div className="single-project-img bg-property" style={{ backgroundImage: `url(${img})` }}></div>
        <div className="single-project-content">
          <h3 style={{ fontSize: '30px' }}>{heading}</h3>
          <p style={{ fontSize: '18px' }}>{description}</p>
        </div>
      </div>
    );
  }
  
  const ProjectStyleOne = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [slidesToShow, setSlidesToShow] = useState(3);
  
    const dotStyles = {
      fontSize: '20px', // Change the font size to adjust dot size
      color: (i) => (i === currentSlide ? '#1A1B16' : '#b0ada7'), // Dynamic color based on index
      position: 'relative',
      transition: 'none', // Disable transition
    };
  
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow,
      slidesToScroll: 1,
      autoplay: true, // Enable autoplay
      autoplaySpeed: 3000, // Set the autoplay speed in milliseconds (e.g., 3000ms or 3s)
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      afterChange: (current) => setCurrentSlide(current),
      customPaging: (i) => (
        <div style={{ ...dotStyles, color: dotStyles.color(i) }}>
          ●
        </div>
      ),
    };
  
    useEffect(() => {
      const handleResize = () => {
        // Adjust slidesToShow based on screen width
        if (window.innerWidth < 768) {
          setSlidesToShow(1);
        } else if (window.innerWidth < 992) {
          setSlidesToShow(2);
        } else {
          setSlidesToShow(3);
        }
      };
  
      // Add event listener for window resize
      window.addEventListener('resize', handleResize);
  
      // Initial setup
      handleResize();
  
      // Cleanup event listener on component unmount
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  
    return (
      <div className="project-area pt-110 pb-110">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="section-title text-center mb-70">
                <h1>Οι Κατηγορίες μας</h1>
              </div>
            </div>
          </div>
          <div className="row mb-60">
            <div className="col-12">
              <div className="centered-carousel">
                <Slider {...settings} className="custom-slider">
                  {ProjectStyleOneData.map((data) => (
                    <ProjectStyleOneItem
                      key={data.id}
                      img={data.img}
                      heading={data.heading}
                      description={data.description}
                      btnLink={data.btnLink}
                    />
                  ))}
                </Slider>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="project-btn text-center">
                <Link to="/menu" className="l-btn">
                  ΤΟ ΜΕΝΟΥ ΜΑΣ
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default ProjectStyleOne;