import React from 'react';
import HeaderTop from '../common/header/HeaderTop';
import HeaderBottomTwo from '../common/header/HeaderBottomTwo';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import breadcrumbBg from '../assets/images/food.jpg';
import ContactForm from '../components/contact/ContactForm';
import TestimonialStyleThree from '../components/testimonial/TestimonialStyleThree';
import ContactMap from '../components/contact/ContactMap';
import Subscribe from '../components/subscribe/Subscribe';
import FooterOne from '../common/footer/FooterOne';

const Contact = () => {
    return (
        <>
            {/*<HeaderTop />*/}
            <HeaderBottomTwo />
            <Breadcrumb 
                breadcrumbBg={breadcrumbBg}
                heading="Επικοινωνία"
                description="Είμαστε στη διάθεση σας από την Τρίτη έως την Κυριακή, από τις 17:00 έως τις 00:30"
                currentPage="Επικοινωνία" 
            />
            <ContactForm />
            {/* <TestimonialStyleThree /> */}
            <ContactMap />
            {/* <Subscribe /> */}
            <FooterOne />
        </>
    )
}

export default Contact;