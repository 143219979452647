import React from 'react';
import {Link} from 'react-router-dom';
import Logo from '../../components/logo/Logo';
import Nav from './Nav';
import MobileMenu from './MobileMenu';
import PhoneImgIcon from '../../assets/images/icon/phone.png';
import { FaPhoneAlt } from 'react-icons/fa';

const HeaderBottomOne = () => {
    return (
        <>
            <div style={{ backgroundColor: '#1A1B16' }} className="header-bottom ">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-xl-2 col-lg-2 col-md-4">
                            <Logo />
                        </div>
                        <div className="col-xl-10 col-lg-10 col-md-8">
                            {/* <div className="header-btn f-right">
                                <Link to="#" className="l-btn">Get a Quote</Link>
                            </div> */}
                            <div className="header-call-btn f-right" style={{ cursor: 'pointer', marginTop: '-5px', marginRight: '100px' }} onClick={() => window.location.href = 'tel:+302321023111'}>
                                <a href="tel:+302321023111" style={{ padding: '0px', display: 'inline-block', textDecoration: 'none' }}>
                                    <br></br><FaPhoneAlt />+30 23210 23111
                                </a>
                            </div>
                            <Nav />
                        </div>
                        <div className="col-12">
                            <div className="mobile-menu">
                                {/* <br></br> */}
                                <MobileMenu />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <style>
                {`
                .mobile-menu {
                    // position: fixed;
                    // bottom: 0/;
                    // right: -10px;
                    margin-bottom: -100px; /* Adjust this value as needed */
                    // margin-top: -100px;
                    margin-right: -20px; /* Adjust this value as needed */
                }

                /* Define styles for the link when its parent is hovered over */
                .header-call-btn:hover a {
                    color: #A2843D; /* Change color to red on hover */
                }

                @media only screen and (max-width: 1500px) {
                    .header-call-btn a {
                        font-size: 30px;
                    }
                }
                @media (max-width: 1500px) {
                    .header-call-btn {
                        cursor: pointer;
                    }
                }
                `}
            </style>
        </>
    );
}


export default HeaderBottomOne;