import React from 'react';
import {Link} from 'react-router-dom';
import FooterBG from '../../assets/images/footer-1.png';
import { FaPhoneAlt, FaFacebookF, FaTwitter, FaInstagram, FaPinterestP, FaAngleDoubleRight, FaLocationArrow } from 'react-icons/fa';

const FooterOne = () => {
    return (
        <>
            <footer>
                <div className="footer-top bg-property theme-bg-2 pt-110 pb-80" style={{ backgroundImage: `url(${FooterBG})` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                <div className="footer-widget-1">
                                    <div className="footer-title">
                                        <h4 style={{ fontSize: '28px' }}>Επικοινωνία</h4>
                                    </div>

                                    <div className="footer-text">
                                        <p style={{ fontSize: '18px' }}>Για παραγγελίες ή ερωτήσεις καλέστε μας στο παρακάτω τηλέφωνο</p>
                                    </div>
                                    <div className="footer-info">
                                        <a href="tel:+302321023111"><FaPhoneAlt />+30 23210 23111</a>
                                    </div>
                                    <div className="footer-text">
                                        <p style={{ fontSize: '18px' }}>Εδέσσης 17, Σέρρες</p>
                                    </div>
                                    <div className="footer-info">
                                        <a href="https://maps.app.goo.gl/tXY9qd8i2Xv93YiJA" target="_blank"><FaLocationArrow /> Βρείτε μας εδώ</a>
                                    </div>
                                    <div className="footer-text">
                                        <h4 style={{ fontSize: '28px' }}>Ώρες Λειτρουργίας</h4>
                                    </div>
                                    <div className="footer-text">
                                        <p style={{ fontSize: '18px' }}>Τρίτη -  Κυριακή: 17:00 - 00:30</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                <div className="footer-widget-1">
                                    <div className="footer-title">
                                        <h4 style={{ fontSize: '28px' }}>Σχετικά με μας</h4>
                                    </div>
                                    <div className="footer-text">
                                        <p style={{ fontSize: '18px' }}>Στο Ratatouille, σας φέρνουμε αυθεντικές γεύσεις με φρέσκα υλικά, απευθείας στην πόρτα σας. Απολαύστε μία γαστρονομική εμπειρία από την άνεση του σπιτιού σας</p>
                                    </div>
                                </div>
                                <div className="footer-social mt-35">
                                    <h4 style={{ fontSize: '28px' }}>Ακολουθήστε μας:</h4>
                                        <ul>
                                            <li>
                                                <a href="https://www.facebook.com/RatatouilleSerres" target="_blank" style={{ fontSize: '22px' }}>
                                                    {/* Add the icon and the text here */}
                                                    <span style={{ marginRight: '5px' }}>
                                                        {/* Import the icon of your choice */}
                                                        <FaFacebookF />
                                                    </span>
                                                    Facebook
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.instagram.com/ratatouille_cuisine_serres" target="_blank" style={{ fontSize: '22px' }}>
                                                    {/* Add the icon and the text here */}
                                                    <span style={{ marginRight: '5px' }}>
                                                        {/* Import the icon of your choice */}
                                                        <FaInstagram />
                                                    </span>
                                                    Instagram
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                            </div>
                            
                            {/* <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                <div className="footer-widget-4">
                                    <div className="footer-title">
                                        <h4>Ώρες Λειτρουργίας</h4>
                                    </div>
                                    <div className="footer-text">
                                        <p>Τρίτη -  Κυριακή: 17:00 - 00:30</p>
                                    </div>
                                    <div className="footer-social mt-35">
                                        <h5>Ακολουθήστε μας:</h5>
                                        <ul>
                                            <li>
                                                <a href="https://www.facebook.com/RatatouilleSerres" target="_blank" style={{ fontSize: '20px' }}>
                                                    {/* Add the icon and the text here 
                                                    <span style={{ marginRight: '5px' }}>
                                                        {/* Import the icon of your choice 
                                                        <FaFacebookF />
                                                    </span>
                                                    Facebook
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.instagram.com/ratatouille_cuisine_serres" target="_blank" style={{ fontSize: '20px' }}>
                                                    {/* Add the icon and the text here 
                                                    <span style={{ marginRight: '5px' }}>
                                                        {/* Import the icon of your choice 
                                                        <FaInstagram />
                                                    </span>
                                                    Instagram
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
{/* 
                <div className="footer-bottom theme-bg-1 pt-40 pb-30">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                <div className="copyright">
                                    <p>© 2024 <Link to="/">Landi</Link>. All right reserved.</p>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 text-xl-end text-lg-end text-md-end text-center">
                                <div className="footer-menu">
                                    <ul>
                                        <li><Link to="/">About Landi</Link></li>
                                        <li><Link to="/">Careers</Link></li>
                                        <li><Link to="/">Privacy Policy</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </footer>
        </>
    )
}

export default FooterOne;