import React from 'react';
import HeaderTop from '../common/header/HeaderTop';
import HeaderBottomTwo from '../common/header/HeaderBottomTwo';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import ProjectStyleFour from '../components/project/ProjectStyleFour';
import CtaTwo from '../components/cta/CtaTwo';
import WhyChoseUsTwo from '../components/why-choose-us/WhyChoseUsTwo';
import Subscribe from '../components/subscribe/Subscribe';
import FooterOne from '../common/footer/FooterOne';
import HeaderBottomOne from '../common/header/HeaderBottomTwo';

const Menu = () => {
    return (
        <>
            {/*<HeaderTop />*/}
            <HeaderBottomOne />
            {/* <Breadcrumb 
                breadcrumbBg={breadcrumbBg}
                heading="Το Μενού μας"
                description="Ανακαλύψτε το μενού μας σήμερα και ετοιμαστείτε για μια πραγματική γευστική περιπέτεια!"
                currentPage="Το Μενού μας" 
            /> */}
            <ProjectStyleFour />
            {/* <CtaTwo /> */}
            {/* <WhyChoseUsTwo /> */}
            {/* <Subscribe /> */}
            <FooterOne />
           
        </>
    )
}

export default Menu;