import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ModalVideo from 'react-modal-video';
import { FaPlay } from "react-icons/fa";
import HeroStyleOneImg1 from '../../assets/images/food.jpg';
import HeroStyleOneImg2 from '../../assets/images/clu.jpg';

const HeroStyleOne = () => {
    const [isOpen, setOpen] = useState(false);
    return (
        <>
            <ModalVideo
                channel="youtube"
                autoplay
                isOpen={isOpen}
                videoId="VeJhCx9O--M" // Update the videoId to the Shorts video ID
                onClose={() => setOpen(false)}
            />
            <div className="hero-area height-800 bg-property d-flex align-items-center" data-overlay="black" data-opacity="7" style={{ backgroundImage: `url(${HeroStyleOneImg1})` }}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div className="hero-wrapper">
                                <h1>Ratatouille <h3 className='text-white'>Taste Experience</h3></h1>
                                <p style={{ fontSize: '22px' }}>Ένας συνδυασμός της παράδοσης με τη σύγχρονη μαγειρική τέχνη, δημιουργώντας μοναδικές γεύσεις που θα ξυπνήσουν κάθε αίσθηση</p>
                                <Link to="/menu" className="l-btn">TO ΜΕΝΟΥ ΜΑΣ</Link>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div className="hero-popup-content">
                                <img src={HeroStyleOneImg2} alt="" />
                                <div className="hero-popup-video">
                                <button 
                                        className="popup-video pulse-effect"
                                        onClick={() => setOpen(true)}>
                                            <FaPlay />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HeroStyleOne;
