import React from 'react';
import { FaLocationArrow, FaClock } from 'react-icons/fa';
import { FaPhoneAlt, FaFacebookF, FaTwitter, FaInstagram, FaPinterestP, FaAngleDoubleRight } from 'react-icons/fa';
import {Link} from 'react-router-dom';

const HeaderTop = () => {
    return (
        <div style={{ backgroundColor: '#CFA349' }} className="header-top pt-10 pb-10">
            <div className="container">
                <div className="row">
                    <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12">
                        <div className="header-info">
                            <ul>
                                <li>
                                    <FaLocationArrow />
                                    <a href="https://maps.app.goo.gl/tXY9qd8i2Xv93YiJA"> Εδέσης 17, Σέρρες</a>
                                </li>
                                <li>
                                    <FaClock /> Τρίτη -  Κυριακή: 17:00 - 00:30
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                        <div className="header-social text-center text-xl-end text-lg-end">
                            <a href="https://www.facebook.com/erateinoserres/?locale=el_GR"><FaFacebookF /></a>
                            {/* <a href="https://www.erateino.gr/"><FaTwitter /></a> */}
                            <a href="https://www.instagram.com/ratatouille_cuisine_serres"><FaInstagram /></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderTop;