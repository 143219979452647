import React from 'react';
import CountUp from 'react-countup';

const FunFact = () => {
    return (
        <div className="fun-fact-area pt-10 pb-10 theme-bg-1">
            <div className="container">
                <div className="row pt-2 pb-2">
                    <div className="col-xl-3 col-lg-3 col-md-3">
                        <div className="single-counter text-center">
                            <h4 className='text-white'>Ποιότητα</h4>
                            <p2 className='text-white'>Όλα μας τα γεύματα παρασκευάζονται καθημερινά με τα πιο αγνά υλικά</p2>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3">
                        <div className="single-counter text-center">
                        <   h4 className='text-white'>Ποικιλία</h4>
                            <p2 className='text-white'>Ανακαλύψτε την ποικιλία του μενού μας, κάθε πιάτο μία γαστρονομική εμπειρία</p2>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3">
                        <div className="single-counter text-center">
                            <h4 className='text-white'>Εξυπηρέτηση</h4>
                            <p2 className='text-white'>Απολαύστε υψηλής ποιότητας εξυπηρέτηση και ατομική προσέγγιση</p2>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3">
                        <div className="single-counter text-center text-sm">
                            <h4 className='text-white'>Ταχύτητα</h4>
                            <p2 className='text-white'>Απολαύστε άμεση παράδοση και την απόλυτη γαστρονομία στην πόρτα σας</p2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FunFact;