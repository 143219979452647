import React from 'react';

const ContactMap = () => {
    return (
        <>
            <div className="map-area">
                <iframe 
                    title="Address"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6014.264444422588!2d23.539119574552483!3d41.087957600681825!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14a971f197b17b01%3A0x4a4f53b7f1184c0b!2sRatatouille%20Cuisine%20Serres!5e0!3m2!1sen!2suk!4v1707008161543!5m2!1sen!2suk">
                </iframe>
            </div>
        </>
    )
}

export default ContactMap;