import React from 'react';
import { FaChevronRight } from 'react-icons/fa';
import HomeOneServiceImg1 from '../../assets/images/filosofia.jpg';

const HomeOneService = () => {
    return (
        <div className="service-features white-bg mt-70 pt-70 pb-150">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6">
                        <img className="rounded" src={HomeOneServiceImg1} alt="About Us" />
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 d-flex align-items-center pl-md-5">
                        <div className="service-bullet-wrapper">
                            <div className="section-title mb-20">
                                <h2>Σε εμάς θα βρείτε</h2>
                            </div>
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <div className="service-bullet-list">
                                        <ul style={{ fontSize: '20px', margin: '0', padding: '0' }}>
                                            <li style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
                                                <FaChevronRight style={{ marginRight: '5px' }} />ΕΞΤΡΑ ΠΑΡΘΕΝΟ ΕΛΑΙΟΛΑΔΟ
                                            </li>
                                            <li style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
                                                <FaChevronRight style={{ marginRight: '5px' }} />100% ΜΟΣΧΑΡΙΣΙΟ ΚΙΜΑ
                                            </li>
                                            <li style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
                                                <FaChevronRight style={{ marginRight: '5px' }} />ΦΡΕΣΚΑ ΖΥΜΑΡΙΚΑ
                                            </li>
                                            <li style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
                                                <FaChevronRight style={{ marginRight: '5px' }} />ΧΟΙΡΟΠΟΙΗΤΕΣ ΣΑΛΤΣΕΣ ΚΑΙ ΣΩΣ
                                            </li>
                                            <li style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
                                                <FaChevronRight style={{ marginRight: '5px' }} />ΑΓΝΑ ΥΛΙΚΑ
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomeOneService;