import React from 'react';
import { Link } from 'react-router-dom';
import LogoImg from "../../assets/images/rata.jpg";

const Logo = () => {
    return (
        <div className="logo" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0px' }}>
            <Link to="/" style={{ display: 'inline-block' }}>
                <div style={{ padding: '100px', position: 'relative' }}>
                    <img
                        style={{ height: '162px', width: '279px', position: 'absolute', top:'17px', left: '0' }}
                        src={LogoImg}
                        alt="Logo"
                    />
                </div>
            </Link>
        </div>
    )
}

export default Logo;

// import React from 'react';
// import { Link } from 'react-router-dom';
// import LogoImg from "../../assets/images/rata.jpg";

// const Logo = () => {
//     return (
//         <div className="logo" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '70px' }}>
//             <Link to="/" style={{ display: 'inline-block' }}>
//                 <img
//                     style={{ height: '143px', width: '246px' }}
//                     src={LogoImg}
//                     alt="Logo"
//                 />
//             </Link>
//         </div>
//     )
// }

// export default Logo;